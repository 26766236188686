import React, { Suspense } from 'react'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

// layout HOC
import withLayout from 'src/components/misc/Layout'
import { allFlattenRoutes as routes, allStaticRoutes as staticRoutes } from './index'
import IdleSecurityContainer from 'src/containers/IdleSecurityContainer'
import CacheBuster from 'src/components/CacheBuster'
import Status from 'src/pages/status'

const Routes = () => (
  // rendering the router with layout
  <BrowserRouter>
    <CacheBuster>
      <IdleSecurityContainer>
        <Switch>
          {routes.map((route, index) => {
            return (
              !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact={route.exact}
                  component={withLayout((props) => {
                    return <route.component {...props} />
                  })}></route.route>
              )
            )
          })}
          {staticRoutes.map((route, index) => {
            return (
              !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact={route.exact}
                  component={() => (
                    <Suspense fallback={<div></div>}>
                      <route.component />
                    </Suspense>
                  )}></route.route>
              )
            )
          })}
          <Route path="/status" component={() => <Status />} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </IdleSecurityContainer>
    </CacheBuster>
  </BrowserRouter>
)

export default Routes
