import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'

import App from './App'
import ErrorBoundary from 'src/helpers/ErrorBoundary'
import Maintenance from 'src/pages/other/Maintenance'
import historyUtils from 'src/helpers/historyUtils'
import { StateProvider } from './state'
import * as serviceWorker from './serviceWorker'
import { ToastAlert } from 'src/components/alert/ToastAlert'
import { ModalAlert } from 'src/components/alert/ModalAlert'
import { configureStore } from './redux/store'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from 'src/constants/config'

const onRedirectCallback = (appState) => {
  historyUtils.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
}

ReactDOM.render(
  <ErrorBoundary>
    <Maintenance>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          audience: AUTH0_AUDIENCE,
          redirect_uri: `${window.location.origin}/conta/token`,
        }}>
        <Provider store={configureStore()}>
          <StateProvider>
            <App />
            <ToastAlert />
            <ModalAlert />
          </StateProvider>
        </Provider>
      </Auth0Provider>
    </Maintenance>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
